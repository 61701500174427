.navbar.sticky-bar {
    margin-bottom: 22px;
}

.navbar > .container {
	justify-content: space-between;
}

.navbar.sticky-bar .navbar-brand a {
	color: #fff !important;
}

.navbar-light .navbar-nav .nav-link {
	font-size: 12px;
	font-weight: 400;
	text-transform: uppercase;
	letter-spacing: 0.6px;
}

.navbar-dropdown-flags-select {
	width: 130px;
}

.navbar-dropdown .dropdown-menu {
	min-width: 8rem;
}

.navbar-dropdown-flags-select .btn-outline-dark:not(:disabled):not(.disabled).active, .btn-outline-dark:not(:disabled):not(.disabled):active, .show>.btn-outline-dark.dropdown-toggle {
	color: #343a40;
	background-color: white;
	border-color: #343a40;
	box-shadow: unset;
}

.navbar-dropdown-flags-select.btn-outline-dark:hover {
	color: #343a40;
	background-color: white;
	border-color: #343a40;
	box-shadow: unset;
}

.navbar-dropdown-flags-select .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .show>.btn-outline-dark.dropdown-toggle:focus {
	box-shadow: unset;
}
