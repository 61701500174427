
/*
 * Search results - table
**/
.Results .search-results-table {
	background-color: #f9f9f9;
}

.results-global-container {
	padding-bottom: 110px;
}

/* Table HEAD */
.results-global-container .table-row-head {
	margin: 23px 0 19px;
}

.results-global-container .table-row-head .table-col-th {
	color: #a6adb3;

	text-align: center;
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;

	position: relative;
	cursor: pointer;
}

.results-global-container .table-row-head .table-col-th .sort-icon {
	position: absolute;
	top: 25%;
	right: -15px;
	color: #2685ff;
}

.results-global-container .table-row-head .table-col-th.name {
	flex: 0 0 175px;
}

.results-global-container .table-row-head .table-col-th.name .sort-icon {
    right: 15px;
    top: 5px;
}

.results-global-container .table-row-head .table-col-th.domain .sort-icon {
    top: 5px;
}

.results-global-container .table-row-head .table-col-th.status .sort-icon {
    top: 5px;
}

.results-global-container .table-row-head .table-col-th .empty-cell {
	width: 55px;
}

/* Table BODY */
.results-global-container .table-row-body {
	margin-bottom: 6px;
}

.results-global-container .table-row-body .table-col-tr.type-column {
	flex: 0 0 105px;
}

.results-global-container .table-row-body .table-col-tr.type-column p {
	text-align: center;
	font-size: 14px;
	font-weight: 700;
	line-height: 18px;

	padding-right: 45px;
}

.results-global-container .table-row-body .table-col-tr.type-column p.input {
	color: #00CFBF;
}
.results-global-container .table-row-body .table-col-tr.type-column p.output {
	color: #FFB129;
}
.results-global-container .table-row-body .table-col-tr.type-column p.outcome {
	color: #3C98DB;
}
.results-global-container .table-row-body .table-col-tr.type-column p.impact {
	color: #A35AB6;
}

.results-global-container .table-row-body .table-col-tr.extended {
	border-radius: 0 4px 4px 0;
	border-top : 1px solid #e0e0e1;
	border-right: 1px solid #e0e0e1;
	border-bottom: 1px solid #e0e0e1;
	border-left: 3px solid #00cfbf;

	padding: 27px 22px 27px 31px;
	margin-left: 25px;

	background-color: #ffffff;
}


.results-global-container .table-row-body .table-col-tr.extended p {
	margin: 0;
	padding: 0;

	word-wrap: break-word;
}

.results-global-container .table-row-body .table-col-tr.extended p.level {
	flex: 0 0 180px;
}

.results-global-container .table-row-body .table-col-tr.extended p.level i {
	font-size: 24px;
}

.results-global-container .table-row-body .table-col-tr.extended.input p.level i {
	color: #00CFBF;
}
.results-global-container .table-row-body .table-col-tr.extended.input {
	border-left: 3px solid #00cfbf;
}

.results-global-container .table-row-body .table-col-tr.extended.output p.level i {
	color: #FFB129;
}
.results-global-container .table-row-body .table-col-tr.extended.output {
	border-left: 3px solid #FFB129;
}

.results-global-container .table-row-body .table-col-tr.extended.outcome p.level i {
	color: #3C98DB;
}
.results-global-container .table-row-body .table-col-tr.extended.outcome {
	border-left: 3px solid #3C98DB;
}

.results-global-container .table-row-body .table-col-tr.extended.impact p.level i {
	color: #A35AB6;
}
.results-global-container .table-row-body .table-col-tr.extended.impact {
	border-left: 3px solid #A35AB6;
}

.results-global-container .table-row-body .table-col-tr.extended p.name {
	flex: 0 0 290px;
	height: 54px;

    color: #646569;
    font-size: 15px;
    font-weight: 700;
	line-height: 18px;

    white-space: unset;
    word-break: break-word;
    overflow: hidden;
	text-overflow: ellipsis;

    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.results-global-container .table-row-body .table-col-tr.extended p.domain {
	color: #646569;
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;

	flex: 0 0 79px;
    text-align: center;
    margin-left: 10px;
}

.results-global-container .table-row-body .table-col-tr.extended p.continuum {
	color: #646569;
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;

	flex: 0 0 85px;
    text-align: center;
    margin-left: 50px;
}

.results-global-container .table-row-body .table-col-tr.extended p.status {
	flex: 0 0 120px;
    text-align: center;
    margin: 0 25px 0 40px;
}

.results-global-container .table-row-body .table-col-tr.extended p.status .tag-status {
    padding: 7px 16px;

    border-radius: 4px;
	background-color: #fee3b3;

    color: #646569;
    font-size: 12px;
    font-weight: 700;
	line-height: 18px;
	display: inline-block;
}

.results-global-container .table-row-body .table-col-tr.extended p.quality {
	color: #646569;
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;

	flex: 0 0 60px;
	text-align: center;
    margin-right: 39px;
}

.results-global-container .table-row-body .table-col-tr.extended p.use {
	color: #646569;
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;

	flex: 0 0 70px;
	margin-right: 48px;
	text-align: center;
}


.results-global-container .table-row-body .table-col-tr.extended p.continuum {
	width: 72px;
}

.results-global-container .table-row-body .table-col-tr.extended p.open {
    border-left: 1px solid #e0e0e1;
    padding-left: 17px;
}

.results-global-container .table-row-body .table-col-tr.extended p.open i {
	color: #646569;
	font-size: 23px;
}

/***********************************
** Pagination styles
********************************/

.Results .paginationStyle {
	margin: 20px 0;
}
.Results .paginationStyle:after {
  content: '';
  width: 100%;
  display: inline-block;
}

.paginationStyle .page-item *:focus {
	outline: none;

	border-color: inherit;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.paginationStyle .page-item a {
	background-color: unset;
	background-image: unset;
	border: none;
	color: #b1b1b1;
	font-weight: bold;
}

.paginationStyle .arrows-style a {
	font-size: 26px;
	line-height: 0.55;
	font-weight: lighter !important;
}

.paginationStyle .page-item.active span {
	border-radius: 3px;
	background-color: #74b8ff;
	border: 1px solid #74b8ff;

	padding: 0.25rem .5rem;
    margin: 3px 0 0 0;
}


/*** END: pagination ***/

.Results .expand-view {
	display: none !important;
	margin: 35px 0 0 0;
	width: 100%;
}

.Results .expand-view .left-section {
	margin-right: 20px;
	flex: 0 0 70%;
}

.Results .expand-view .left-section .numerator-denominator {
	margin-bottom: 55px;
}

.Results .expand-view .left-section .numerator-denominator .numerator {
	margin-right: 30px;
}

.Results .expand-view .left-section .numerator-denominator .name {
	color: #a6adb3;
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;
}

.Results .expand-view .left-section .numerator-denominator .info {
	color: #a6adb3;
	font-size: 14px;
	font-weight: 700;
	line-height: 16px;
}

.Results .expand-view .list-service p {
	flex: 0 0 13%;
	text-align: center;
}

.Results .expand-view .list-service p,
.Results .expand-view .props p {
	margin-right: 45px;
	margin-bottom: 20px;

	color: #a6adb3;
	font-weight: 700;
	line-height: 16px;
	text-transform: uppercase;
}

.Results .expand-view .list-service p {
	font-size: 14px;
}

.Results .expand-view .props p {
	font-size: 10px;
}

.Results .expand-view .list-service p.deactive i{
	display: none;
}

.Results .expand-view .list-service p.deactive span,
.Results .expand-view .props p.deactive span,
.Results .expand-view .props p.deactive i {
	color: #d7dfe6;
	font-weight: 700;
	line-height: 16px;
}

.Results .expand-view .props p.deactive i {
	border: 1px solid #d7dfe6;
	color: #d7dfe6;
	font-size: 11px;
	font-weight: 700;
	line-height: unset;
}


.Results .expand-view .list-service p span,
.Results .expand-view .props p span {
	max-width: 150px;
	line-height: 16px;
}

.Results .expand-view .list-service p i,
.Results .expand-view .props p i {
	color: #22d5c8;
	margin-right: 8px;
}

.Results .expand-view .list-service p i {
	font-size: 14px;
}

.Results .expand-view .props p i {
	border-radius: 100%;
    font-size: 10px;
    line-height: unset;
    width: 24px;
    height: 22px;
    border: 1px solid #17d3c5;
    padding: 3px 0 0 6px;
    margin-top: 5px;
}

.Results .results-footer {
	width: 95%;
	margin: 35px auto 0;
}

.Results .results-footer p.total-results {
	color: #b1b1b1;
	margin: 0 15px 0 0;
}
