.Header {
	width: 100%;
	height: 330px;
	background-color: #1f54a4;
	position: relative;
}

.Header .container {
	display: flex;
	justify-content: center;
	flex-direction: row;
	align-items: center;
}

.Header .intro-section {
	width: 523px;
	top: 40px;
	left: 70px;
}

.Header .intro-section h1 {
	color: #fff;
	font-weight: 700;
	font-size: 32px;
}

.Header .intro-section h1 span.sub-text {
	color: #fff;
	font-weight: 400;
}

.Header .intro-section p {
	color: #fff;
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	margin: 0;
}

.Header .intro-section p  a{
	color: white;
}

.Header .intro-section p:first-child {
	margin-top: 25px;
}

.header-title-text {
	font-size: 32px;
	font-weight: 500;
}

.header-title-sub-text {
	font-size: 32px;
	font-weight: 300;
}
