.Filter .search-for-project-form {
	border-radius: 4px;
	background-color: #f7f9fb;
	padding: 24px 20px;
}

.Filter .clear-filters-wrapper {
	display: flex;
	justify-content: flex-end;
}

.Filter .form-label {
	text-transform: uppercase;
	font-size: 12px;
}

.Filter .documents-links .btn-outline-primary {
	color: #4ea547;
	border-color: #4ea547
}

.Filter .btn-outline-primary:hover {
	background-color: #4ea547;
	color: white;
}

.Filter .btn-outline-primary:hover i {
	color: white;
}

.Filter .search-panel-label {
	font-size: 24px;
	font-weight: 400;
	line-height: 40px;

	margin-left: 20px;
}

.Filter .search-project-btn {
	width: 79px;
    height: 38px;
    align-self: center;
    margin-top: 15px;
}

.Filter .filter-fields {
	margin-bottom: 85px;
}

/*
 * Results - basic panel
**/
.Filter .result-basic-panel h4 {
	color: #646569;
	font-size: 18px;
	font-weight: 400;
}

.Filter .result-basic-panel h4 span {
	color: #fcba42;
	font-size: 24px;
	font-weight: 400;

	margin-right: 15px;
}

.Filter .result-basic-panel .result-tag {
	font-size: 14px;
	font-weight: 400;
	color: #646569;

    border-radius: 4px;
	background-color: #edeff0;

	margin: 4px;
}

.Filter .tag-close-ico {
	margin-left: 9px;
}

.result-basic-panel .save-results {
	border-left: 1px solid #e0e0e1;
    padding-left: 17px;
}



/*
 * Sort by line - All / Global / National
**/

.sort-by-basic-panel {
	margin: 30px 0 25px 0;
}

.sort-by-basic-panel p {
	color: #646569;
	font-size: 16px;
	border: unset;
}

.sort-by-basic-panel .list-group {
	height: 28px;
	border: unset;
}

.sort-by-basic-panel .list-group .list-group-item {
	padding: 2px 8px;
	border-radius: 0;
	border: unset;

	color: #a6adb3;
	font-size: 14px;
	font-weight: 700;
	line-height: 18px;
	text-transform: uppercase;

	cursor: pointer;

	margin-left: 60px;
}

.sort-by-basic-panel .list-group .list-group-item:hover,
.sort-by-basic-panel .list-group .list-group-item.active {
	background-color: unset;
	color: #01468b;
	font-size: 14px;
	font-weight: 700;
	line-height: 18px;
	text-decoration: underline;
	text-transform: uppercase;
}

.Filter .clear-filters {
	margin-top: 10px;
	cursor: pointer;
}

.Filter .autocomplete-filter .aa-Submit {
	margin-top: 3px;
}
